form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

input,
textarea,
button {
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
