p {
  text-align: center;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.flex-container .box {
  color: white;
  width: 100%;
  margin: 10px;
  text-align: center;
  font-size: 25px;
  padding: 10px;
}

.background-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  filter: brightness(50%);
}

.hero-container {
  /* background: url("../../public/images/heroimage2.jpg") center center/cover
    no-repeat; */
  opacity: 1;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container h1 {
  color: #ffffff;
  font-size: 70px;
  margin-left: 10%;
  margin-right: 10%;
  /* margin-top: 10%; */
  /* -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 3px; */
}

.hero-container h2 {
  margin-top: 8px;
  color: #ffffff;
  font-size: 35px;
  margin-left: 20%;
  margin-right: 20%;
}

.hero-container p {
  font-size: 25px;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 8px;
  color: white;
}

.fa-play-circle {
  margin: 4px;
}

.btn a {
  color: black; /* Inherit the color from the parent element */
  text-decoration: none; /* Remove underline */
}

.btn {
  margin-top: 8px;
  background-color: white !important;
}

.btn:hover {
  background-color: gray !important;
}

@media screen and (max-width: 960px) {
  .hero-container h1 {
    font-size: 70px;
    /* margin-top: -50px; */
  }
}

@media screen and (max-width: 768px) {
  .hero-container h1 {
    font-size: 40px;
    margin-top: 50px;
  }

  .hero-container h2 {
    font-size: 25px;
  }
  .hero-container p {
    font-size: 20px;
  }
}
