@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.hero {
  overflow: hidden;
  margin-top: 10vh;
}

a {
  color: inherit;
  text-decoration: none; /* Adjust as needed */
}

.hero-content p a {
  color: inherit;
  text-decoration: none;
}
.heading h1 {
  font-size: 55px;
  text-align: center;
  margin-top: 35px;
  font-style: italic;
  color: rgb(0, 0, 0);
  /* -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 1px; */
}

.heading h3 {
  font-size: 28px;
  text-align: center;
  margin-top: 25px;
  font-style: italic;
  color: rgb(0, 0, 0);
  /* -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 1px; */
}

.heading a {
  color: black;
}

.heading a:active {
  background: rgba(93, 93, 93, 0.7);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  /* margin: 65px auto; */
}
.hero-content {
  flex: 1;
  width: 600px;
  /* margin: 0px 25px; */
  animation: fadeInUp 2s ease;
  text-align: center; /* Center the content including the button */
}
.hero-content h2 {
  text-align: center;
  font-size: 38px;
  margin-bottom: 20px;
}
.hero-content h3 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
}
.hero-content h4 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
.hero-content p {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 15px;
  color: black;
}
.hero-image {
  flex: 1;
  width: 600px;
  margin: auto;
  animation: fadeInRight 2s ease;
  display: flex; /* Add flexbox */
  justify-content: center; /* Center image horizontally */
}

.hero-image-left {
  flex: 1;
  width: 600px;
  margin: auto;
  animation: fadeInLeft 2s ease;
  display: flex; /* Add flexbox */
  justify-content: center;
}

img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.headshot {
  width: 75%;
  height: auto;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .heading h1 {
    font-size: 45px;
    margin-top: 30px;
  }
  .heading h3 {
    font-size: 25px;
  }
  .hero {
    margin-top: 10vh;
  }
  .container {
    width: 100%;
    flex-direction: column;
    margin: 0px;
    padding: 0px 40px;
  }
  .hero-content {
    width: 75%;
    margin: 35px 0px;
  }
  .hero-content h2 {
    font-size: 30px;
    text-align: center;
  }
  .hero-content h3 {
    font-size: 26px;
    text-align: center;
  }
  .hero-content h4 {
    font-size: 20px;
    text-align: center;
  }
  .hero-content p {
    font-size: 16px;
    text-align: center;
  }
  .hero-image {
    width: 100%;
  }
  .hero-image-left {
    width: 100%;
  }
  /* For the second container - reverse the order */
  .reverse-order .hero-content {
    order: 1; /* Text appears first */
  }

  .reverse-order .hero-image-left {
    order: 2; /* Image appears second */
    width: 100%;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 100;
    transform: translateY(0px);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 100;
    transform: translateX(0px);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 100;
    transform: translateX(0px);
  }
}
